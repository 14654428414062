import axios from '@axios'

export default {
  namespaced: true,
  state: {
    orders: null
  },
  getters: {
  },
  mutations: {
      getOrders(state, orders) {
        state.orders = orders;
      }
  },
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/invoices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/invoice/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrders({ commit }) {
      // return new Promise((resolve, reject) => {
        console.log("store")

        this.$axios.post(this.$services + "bot/getOrders", {
          broker: 1,
          // date = req.body.date,
          // endDate = req.body.endDate,
          }).then(res => {
          console.log(res.data);
          if (!res.data.error) {
            console.log(res.data.data)
            // this.data = res.data.data;
            commit("getOrders", res.data.data);
            // return (res.data.data)
  
          }
        }).catch(error => reject(error));

        // axios
        //   .get('/apps/invoice/invoices', { params: queryParams })
        //   .then(response => resolve(response))
        //   .catch(error => reject(error))
      // })
      // this.$axios.post(this.$services + "bot/getOrders", {
      //   broker: 1,
      //   // date = req.body.date,
      //   // endDate = req.body.endDate,
      //   }).then(res => {
      //   // console.log(res.data);
      //   if (!res.data.error) {
      //     // this.data = res.data.data;
      //     commit("getOrders", res.data.data);

      //   }
      // }).catch(error => console.log(error));
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
